$c-primary: #0653b6;
$c-secandary: #0653b6;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #bbbbbb;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'BMWTypeNextTT', sans-serif;
$font-family-heading: 'BMWTypeNextTT', sans-serif;

      @font-face {
        font-family: 'BMWTypeNextTT';
        src: url('/assets-mobile/fonts/BMWTypeNextTT-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BMWTypeNextTT';
        src: url('/assets-mobile/fonts/BMWTypeNextTT-Bold.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BMWTypeNextTT';
        src: url('/assets-mobile/fonts/BMWTypeNextTT-Light.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BMWTypeNextTT';
        src: url('/assets-mobile/fonts/BMWTypeNextTT-Thin.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Tahoma';
        src: url('/assets-mobile/fonts/Tahoma-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.heading {
  font-family: var(--heading-font-family);
  color: var(--heading-text-color);
  margin-bottom: 1.5rem; //24
  text-align: left;
  font-weight: 600;
  font-size: var(--heading-font-size);
  line-height: 1.1;
  letter-spacing: -0.03125rem; //-0.5px
}
