$c-primary: #0653b6;
$c-secandary: #0653b6;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #bbbbbb;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'BMWTypeNextTT', sans-serif;
$font-family-heading: 'BMWTypeNextTT', sans-serif;

      @font-face {
        font-family: 'BMWTypeNextTT';
        src: url('/assets-mobile/fonts/BMWTypeNextTT-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BMWTypeNextTT';
        src: url('/assets-mobile/fonts/BMWTypeNextTT-Bold.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BMWTypeNextTT';
        src: url('/assets-mobile/fonts/BMWTypeNextTT-Light.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BMWTypeNextTT';
        src: url('/assets-mobile/fonts/BMWTypeNextTT-Thin.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Tahoma';
        src: url('/assets-mobile/fonts/Tahoma-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.progress {
  height: 1.25rem; //20
  background: #f5f5f5;
  position: relative;
  margin-top: 1rem; //16
  border-radius: 0.3125rem; //5

  .bar {
    border-radius: 0.3125rem; //5
    background-color: black;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    transition: width 0.6s ease;
  }
}
