$c-primary: #0653b6;
$c-secandary: #0653b6;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #bbbbbb;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'BMWTypeNextTT', sans-serif;
$font-family-heading: 'BMWTypeNextTT', sans-serif;

      @font-face {
        font-family: 'BMWTypeNextTT';
        src: url('/assets-mobile/fonts/BMWTypeNextTT-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BMWTypeNextTT';
        src: url('/assets-mobile/fonts/BMWTypeNextTT-Bold.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BMWTypeNextTT';
        src: url('/assets-mobile/fonts/BMWTypeNextTT-Light.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BMWTypeNextTT';
        src: url('/assets-mobile/fonts/BMWTypeNextTT-Thin.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Tahoma';
        src: url('/assets-mobile/fonts/Tahoma-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.checkbox {
  display: none;
}

.option_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  height: 2.8125rem; //45
  margin-bottom: 0.6875rem; //11
  cursor: pointer;
  &.checked {
    background: #f4f4f4;
    border-radius: 10px;
  }

  .check_mark_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 3.3125rem; //53
    height: 2.8125rem; //45

    img {
      width: 1.375rem; //22
      height: auto;
    }
  }

  .option_title {
    flex: 1;
  }
}
