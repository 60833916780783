$c-primary: #0653b6;
$c-secandary: #0653b6;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #bbbbbb;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'BMWTypeNextTT', sans-serif;
$font-family-heading: 'BMWTypeNextTT', sans-serif;

      @font-face {
        font-family: 'BMWTypeNextTT';
        src: url('/assets-mobile/fonts/BMWTypeNextTT-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BMWTypeNextTT';
        src: url('/assets-mobile/fonts/BMWTypeNextTT-Bold.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BMWTypeNextTT';
        src: url('/assets-mobile/fonts/BMWTypeNextTT-Light.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BMWTypeNextTT';
        src: url('/assets-mobile/fonts/BMWTypeNextTT-Thin.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Tahoma';
        src: url('/assets-mobile/fonts/Tahoma-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.Select {
  position: relative;
  .hasError {
    text-align: left;
    font-size: 0.8rem;
    margin-top: 3px;
    color: $c-danger;
  }
}

.leftPadding30Perc {
  padding: 0.5rem 0.7rem 0.5rem 40% !important;
}

:export {
  primary: var(--primary);
  secondary: var(--secondary);
  primary25: '#fff';
  primary50: lighten($c-secandary, 45%);
  gray: $c-grey;
  bodyGray: #fff;
}
